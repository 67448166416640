@use 'Styles' as *;

.searchResultsContainer {
  display: flex;
  padding: 1.34rem 1rem;
  padding-bottom: 2rem;

  @media screen and (max-width: $newTablet-max) {
    padding-bottom: 1.34rem;
  }
}

.cardInfoContainer {
  width: 100%;
}

.cardIconButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0 3.34rem;
  margin-bottom: 1.25rem;
}

.cardButtonLinkContainer {
  display: flex;
  button {
    padding: 0.334rem 1.002rem;
    margin-right: 0.67rem;
  }

  @media screen and (max-width: $newTablet-max) {
    flex-direction: column;
    margin-bottom: 0.5rem;
    button: {
      margin-right: 0rem;
    }
    button:last-child {
      margin-top: 0.67rem;
    }

    width: 100%;
  }
}

.buttonContainerMapView {
  display: flex;
  button {
    padding: 0.334rem 1.002rem;
    margin-right: 0.67rem;
  }
}

.cardStoreAddressContainer {
  margin-bottom: 1.336rem;
}

.addressLine1 {
  display: flex;
}
