@use 'Styles' as *;

.accordionContainer {
  > div {
    width: fit-content;

    > div {
      border-bottom-width: 0 !important;
    }
  }

  button {
    padding: 0 0.5rem 0 0;
  }
}
