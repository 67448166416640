@use 'Styles' as *;

.mapContainer {
  display: block;
  position: relative;
  height: 100%;
  width: 100vw;
  max-width: 100%;
}

.pinElement {
  @include Text_UI_M($color: ui-red-100, $bold: true);
}

.cardsContainer {
  display: flex;
  gap: getSize(s30);
  position: absolute;
  bottom: getSize(s50);
  left: getSize(s50);
  max-width: 100vw;
  overflow-x: scroll;
  padding-right: getSize(s50);
}
