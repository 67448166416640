@use 'Styles' as *;

.cardStoreTimingContainer {
  display: flex;
  align-items: center;
}

.accordionHeading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.accordionBody {
  max-width: 260px;
}

.storeHoursGrid {
  justify-content: space-between;
}
